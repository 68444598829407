
.song-time{
    height: 100vh;
    position: relative;
    overflow: hidden;
}
.song-time .route_bar{
    background: none;
    box-shadow: none;
    padding-right:0;
}
.song-time .bg{
    position: absolute;
    left: 0;
    top:0;
    width: 100%;
    height: 100%;
    z-index: 1;
}
.song-time .bg .top{
    width: 24vw;
    height: 19.6vw;
    position: absolute;
    left:0;
    top:0;
    z-index: 2;
}
.song-time .bg .back{
    width: 100%;
    height: 100%;
    position: absolute;
    left:0;
    top:0;
    z-index: 1;
}
.song-time .bg .btm{
    width: 100%;
    position: absolute;
    left:50%;
    bottom:0;
    transform: translateX(-50%);
    z-index: 2;
}
.song-time .box_parent{
    padding: 120px 0 10%;
    position: relative;
}
.song-time .box{
    border:none;
    border-radius: 60px 60px 0 0;
    max-width: 936px;
    max-height: 625px;
    width: 49.5vw;
    box-shadow: 8px 8px 3px rgba(0,0,0,.1);
    position: relative;
    background-color: #8b10d7;
}
.song-time .screen{
    padding: 0;
    padding-top: 98px;
    position: relative;
    z-index: 5;
}
.song-time .video-wrap{
    height: 100%;
    width: 100%;
    background: #000;
    overflow: hidden;
    position: relative;
}
.song-time .video-wrap img, .song-time .video-wrap video{
    width: 100%;
}
.song-title{
    background-color: #8b10d7;
    padding: 19px 20px;
    border-radius: 60px 60px 0 0;
    position: absolute;
    left:0;
    top:0;
    width: 100%;
    z-index: 5;
}
.song-title p{
    text-align: center;
    font-size: 38px;
    color: #fffffe;
}
.song-title p span{
    font-family: 'NanumSquareRound',sans-serif;
    color: #fff;
    font-size:40px;
}
.song-box{
    width: 100%;
}
.box-tab{
    position: absolute;
    right:-190px;
    top:116px;
    z-index: 2;
    width:190px;
}
.box-tab li{
    background-color: #8b10d7;
    border-radius: 0 72px 72px 0;
    box-shadow: 8px 8px 3px rgba(0,0,0,.1);
    margin-bottom:11px;
    width: 90%;
    cursor: pointer;
}
.box-tab li:last-child{
    margin-bottom:0;
}
.box-tab li span{
    font-size:26px;
    color:#fff;
    display: block;
    padding: 15px 32px 15px 0;
    text-align: right;
    font-weight: 600;
}
.box-tab li.on{
    background-color: #fff;
    width: 100%;
}
.box-tab li.on span{
    color: #8b10d7;
}
.box-tab li:hover{
    width: 100%;
}
.countCircle{
    width: 14vw;
    height: 14vw;
    line-height: 14vw;
}  


.leason-up{
    background: linear-gradient(to bottom, #8757ff, #2ac7fe);
}
.leason-up .bg .top{
    background: url(../images/song-time/background-lu-top.png)center no-repeat;
    background-size:100%;
}
.leason-up .bg .back{
    background:url(../images/song-time/background-lu-cc.png)center no-repeat;
    background-size:cover;
}
.leason-up .bg .btm{
    background:url(../images/song-time/background-lu-bar.png)left bottom no-repeat;
    background-size:100% 100%;
    height: 340px;
}
.sing-along{
    background: linear-gradient(to bottom, #7759fe, #da41ff);
}
.sing-along .bg .top{
    background: url(../images/song-time/background-sa-top.png)center no-repeat;
    background-size:100%;
}
.sing-along .bg .back{
    background:url(../images/song-time/background-sa-cc.png)center no-repeat;
    background-size:cover;
}
.sing-along .bg .btm{
    background:url(../images/song-time/background-sa-bar.png)left bottom no-repeat;
    background-size:100% 100%;
    height: 340px;
}
.sing-along .rightbtngroup{
    position: absolute;
    right: -6.5vw;
    bottom:0;
    display: block;
    width:4.5vw;
    text-align: right;
    z-index: 5;
}
.sing-along .rightbtngroup span{
    width: 4.5vw;
    height:4.5vw;
    background: none;
    margin: 0;
    margin-bottom:19px;
    display: inline-block;
    cursor: pointer;
}
.sing-along .rightbtngroup span:last-child{
    margin-bottom:0 !important;
}
.sing-along .rightbtngroup span:hover{
    background: none !important;
}
.sing-along .rightbtngroup span img{
    width:100%;
}
.control_play_btn{/* JHS */
    position:absolute;
    left: 50%;
    top:50%;
    transform: translate(-50%, -50%);
    width:178px;
    height:178px;
}
.control_play_btn img{/* JHS */
    cursor: pointer;
    width:100%;
    height:100%;
}
.control_song_play{/* JHS */
    position:absolute;
    left: 50%;
    top:50%;
    transform: translate(-50%, -50%);
    width:178px;
    height:178px;
}
.control_song_play img{/* JHS */
    cursor: pointer;
    width:100%;
    height:100%;
}
.song-box .btn-area{
    position: relative;
    width: 100%;
    text-align: center;
}
.song-box .btn-area img{
    position: absolute;
    left: 50%;
    top: 0;
    width: 14.5vw;
    height: 5vw;
    cursor: pointer;
    transform: translateX(-50%);
    z-index: 5;
}
@media (min-width: 2000px) {
    .song-time .box{
        max-width: inherit;
        max-height: inherit;
        width: 64.5vw;
    }
    .song-title p{
        font-size: 2vw;
    }
    .song-title p span{
        font-size: 2.3vw;
    }
    .song-time .screen{
        padding-top: 5vw;
    }
}

@media (max-width: 1280px) {
    .control_play_btn{
        width: 10vw;
        height: 10vw;
    }
    .song-title p{
        font-size: 2vw;
    }
    .song-title p span{
        font-size: 2.2vw;
    }
    .song-time .screen{
        padding-top: 6vw;
    }
    .song-time .box{
        width: 64.5vw;
    }
    .box-tab{
        top: 6vw;
        right: -13vw;
        width: 13vw;
    }
    .box-tab li span {
        font-size: 1.5vw;
    }
}

@media (max-width: 1024px), (max-height:768px) {
    .song-time .box_parent{
        padding: 100px 0 11%;
    }
    .song-time .box{
        width: calc(100% - 350px);
        max-height: inherit;
        height: 68.5vh;
    }
    .song-title{
        height: 70px;
        border-radius: 35px 35px 0 0;
        padding: 12px 20px;
    }
    .song-title p{
        font-size: 26px;
    }
    .song-title p span{
        font-size:32px;
    }
    .song-time .screen{
        padding-top: 70px;
    }
    .song-time .bg .top{
        width: 21vw;
        height: 17.2vw;
    }
    .box-tab {
        right: -155px;
        top: 97px;
        width: 155px;
    }
    .box-tab li span{
        font-size: 20px;;
    }
    
    .leason-up .bg .btm{
        height: 32vh;
        background-size: 100%;
    }
    .sing-along .bg .btm{
        height:45%;
    }
    .sing-along .rightbtngroup{
        width: 86px;
        right: -86px;
    }
    .sing-along .rightbtngroup span{
        width: 70px;
        height: 70px;
        margin-bottom: 13px;
    }
    .control_play_btn{/* JHS */
        width: 14vw;
        height: 14vw;
    }
    .control_song_play{/* JHS */
        width: 14vw;
        height: 14vw;
    }
    
    .song-box .btn-area img{
        width: 195px;
        height: 69px;
    }
}
/*태블릿 7인치 이하*/
@media (max-height:650px) {
    .song-time .box_parent {
        padding: 60px 0 9%;
    }
    .song-time .box{
        margin: 0 auto;
        padding-top: 0;
        width: calc(100% - 296px);
        max-height: 460px;
    }
    .song-title{
        height: 60px;
        padding: 10px 15px;
    }
    .song-title p{
        font-size: 24px;
    }
    .song-title p span{
        font-size: 28px;
    }
    .song-time .screen{
        padding-top: 60px;
    }
    .box-tab {
        right: -133px;
        top: 81px;
        width: 133px;
    }
    .box-tab li span {
        font-size: 18px;
        padding: 12px 23px 12px 0;
    }
    .sing-along .rightbtngroup{
        width: 75px;
        right: -75px;
    }
    .sing-along .rightbtngroup span{
        width: 62px;
        height: 62px;
        margin-bottom: 7px;
    }
    
    .song-box .btn-area img{
        width: 164px;
        height: 58px;
    }
}