.time-line {
  height: 100%;
  overflow: hidden;
  background-color: rgb(243, 247, 217);
  background-image: url(../images/background-pattern.png);
  background-repeat: repeat;
  background-size: auto;
  position: fixed;
  width: 100%;
  top: 0px;
  left: 0px;
  /* overflow: hidden; */
}
.time-line .box_parent {
  padding: 115px 0 30px;
}
.time-line .box_parent .box-wrap {
  border: none;
  border-radius: 20px;
  display: block !important;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  max-width: 1490px;
  width: 140vh;
  height: 47.5vw;
  max-height: 100%;
}
.time-line .box_parent .box-wrap:before {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  background-position: left top;
  background-repeat: no-repeat;
  z-index: 1;
}
.time-line .box_parent .box-wrap:after {
  content: "";
  width: calc(100% - 3vw);
  height: calc(100% - 3.5vw);
  position: absolute;
  left: 0;
  top: 0;
  background: #fff;
  border-radius: 15px;
  margin: 2vw 1.5vw 1.5vw 1.5vw;
  z-index: 0;
}
.box_parent .timeline-bg-area {
  width: calc(100% - 3vw);
  height: calc(100% - 3.5vw);
  margin: 2vw 1.5vw 1.5vw 1.5vw;
  position: relative;
  z-index: 2;
}
.time-line .bg {
  position: absolute;
  transition: 0.3s;
  transform: scale(1);
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  /* opacity: 0; */
  z-index: 1;
  background-position: left top;
  background-repeat: no-repeat;
  background-size: 100%;
  display: none;
}
.time-line .bg img {
  width: 100%;
  height: 100%;
}
.timeline-bg-area .pointer {
  cursor: pointer;
  display: block;
  position: absolute;
  z-index: 5;
}

.timeline-bg-area .top {
  transform-origin: top;
}
.timeline-bg-area .top.left {
  transform-origin: top left;
}
.timeline-bg-area .top.right {
  transform-origin: top right;
}
.timeline-bg-area .left {
  transform-origin: left;
}
.timeline-bg-area .right {
  transform-origin: right;
}
.timeline-bg-area .bottom {
  transform-origin: bottom center;
}
.timeline-bg-area .bottom.left {
  transform-origin: bottom left;
}
.timeline-bg-area .bottom.right {
  transform-origin: bottom right;
}
.time-line .bg.on {
  transform: scale(1.2);
  display: none;
  /* display: block;  */
  z-index: 2;
}
.time-line .bg.show {
  display: none;
  /* display: block;  */
  opacity: 1;
  cursor: pointer;
}
.time-pop-inner {
  display: none;
}
.time-line-pop {
  position: absolute;
  z-index: 30;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  max-width: 1050px;
  width: 80vw;
}
.question-layer {
  width: 100%;
  display: flex;
  margin-bottom: 65px;
}
.question-layer .select {
  background: #fff;
  margin-right: 29px;
  border-width: 6px;
  border-color: #9cc813;
  border-style: solid;
  padding: 32px;
  width: calc((100% - 20px) / 2);
  border-radius: 30px;
  max-height: 350px;
  height: 19vw;
  position: relative;
  cursor: pointer;
  box-shadow: 4px 4px 8px rgba(48, 48, 48, 0.4);
}
.question-layer .select:last-child {
  margin-right: 0;
}
.question-layer .select:hover {
  border-color: #f9be00;
}
.question-layer .select:active {
  outline: 6px solid #f9be00;
  outline-offset: -6px;
}
.question-layer .select p {
  font-family: "NanumBarunGothic";
  font-size: 1.6vw;
  font-weight: 600;
  line-height: 1.3;
  position: relative;
  z-index: 2;
  letter-spacing: 0;
}
.question-layer img {
  position: absolute;
  left: 50%;
  bottom: 0;
  transform: translateX(-50%);
  width: 100%;
  z-index: 1;
}
.shadow {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.75);
  z-index: 20;
}
.time-pop-inner{
    display:none;
}
.time-line-pop{
    position: absolute;
    z-index: 30;
    left: 50%;
    top:50%;
    transform: translate(-50%, -50%);
    max-width: 1050px;
    width: 80vw;
}
.question-layer{
    width:100%;
    display: flex;
    margin-bottom:65px;
}
.question-layer .select{
    background: #fff;
    margin-right: 29px;
    border-width: 6px;
    border-color: #9cc813;
    border-style: solid;
    padding:1.5vw 2vw;
    width: calc((100% - 20px) / 2);
    border-radius: 30px;
    max-height:350px;
    height:19vw;
    position: relative;
    cursor: pointer;
    box-shadow: 4px 4px 8px rgba(48, 48, 48, .4);
    outline:none;
}
.question-layer .select:last-child{
    margin-right:0
}
.question-layer .select:hover{
    border-color: #f9be00;
}
.question-layer .select:active{
    outline:none;
    border:6px solid #f9be00;
}
.question-layer .select p{
    font-family: 'NanumBarunGothic';
    font-size:1.6vw;
    font-weight: 600;
    line-height: 1.3;
    position: relative;
    z-index: 2;
    letter-spacing: 0;
}
.question-layer img{
    position: absolute;
    left:50%;
    bottom:0;
    transform: translateX(-50%);
    width:100%;
    z-index: 1;
} 
.shadow{
    position:fixed;
    left:0;
    top:0;
    width:100%;
    height:100%;
    background: rgba(0,0,0,.75);
    z-index: 20;
}


/*에피소드 별 설정*/
/*HHT1*/
/*EP01*/
.time-line .hht1-timeline-ep01 .box-wrap {
  /* background-image: url(../images/time-line/hht1_ep01/sample.png); */
  background-image: url(../images/time-line/hht1_ep01/hht1-timeline-bg01.png);
}
.hht1-timeline-ep01 .box-wrap:before {
  background-image: url(../images/time-line/hht1_ep01/hht1-timeline-bg02.png);
  background-size: auto 100%;
}
/*EP02*/
.time-line .hht1-timeline-ep02 .box-wrap {
}
.hht1-timeline-ep02 .box-wrap:before {
}

/*HHT2*/
/*EP01*/
.time-line .hht2-timeline-ep01 .box-wrap {
  /* background-image: url(../images/time-line/hht2_ep01/sample.png); */
  background: #fff;
  border: 2px solid #dfe1e0;
}
.hht2-timeline-ep01 .box-wrap:before {
  background-image: url(../images/time-line/hht2_ep01/hht2-timeline-bg02.png);
  background-size: auto 100%;
}
.time-line .hht2-timeline-ep02 .box-wrap {
}
.hht2-timeline-ep02 .box-wrap:before {
}

/*에피소드 별 설정*/

@media (min-width: 2000px) {
  .time-line .box_parent .box-wrap {
    max-width: inherit;
    height: 87%;
  }
  .time-line-pop{
    max-width: inherit;
    width: 70vw;
  }
  .question-layer .select{
    max-height: inherit;
    height: 20vw;
  }
}
@media (max-width: 1400px) {
  .time-line .box_parent .box-wrap {
    height: 54.5vw;
  }
}
@media (max-width: 1200px) {
  .time-line .box_parent .box-wrap {
    width: 93%;
  }
}

@media (min-height: 1000px) {
  .time-line .box_parent .box-wrap {
    width: 80vw;
  }
}
@media (max-height: 768px) {
  .time-line .box_parent {
    padding: 85px 0 15px;
  }
  .time-line .box_parent .box-wrap {
    width: 140vh;
    max-width: 90%;
  }
  .time-line-pop {
    width: 75vw;
  }
  .question-layer {
    margin-bottom: 45px;
  }
  .question-layer .select {
    height: 25vw;
    padding: 22px 30px;
  }
  .question-layer .select p {
    font-size: 2vw;
  }
}
@media (max-height: 650px) {
  .time-line .box_parent {
    padding: 75px 0 10px;
  }
  .time-line .box_parent .box-wrap {
    height: 100%;
  }
  .question-layer {
    margin-bottom: 30px;
  }
  .question-layer .select {
    height: 255px;
    padding: 20px 25px;
  }
  .question-layer .select p {
    font-size: 22px;
  }
  .box_parent .timeline-bg-area {
    width: calc(100% - 1.6vw);
    height: calc(100% - 1.8vw);
    margin: 1vw 0.8vw 0.8vw 0.8vw;
  }
  .time-line .box_parent .box-wrap:after {
    width: calc(100% - 1.6vw);
    height: calc(100% - 1.8vw);
    margin: 1vw 0.8vw 0.8vw 0.8vw;
  }
  .timeline-cursor {
    width: calc(100% - 1.6vw);
    height: calc(100% - 1.8vw);
    margin: 1vw 0.8vw 0.8vw 0.8vw;
  }
}
