.egg-studio-new {
  background-color: #2d165a;
  overflow: hidden;
}
.egg-studio-new .route_bar {
  background: none;
  box-shadow: none;
  padding-right:0;
}
.egg-studio-new .box_parent {
  justify-content: center;
  padding-bottom: 0;
}
.egg-studio-new .box_parent .screen {
  background: #412a84;
  padding: 30px 0 30px 25px;
}
.studio_title {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  background-color: #412a84;
  left: 50%;
  transform: translateX(-50%);
  padding: 10px 25px 0 25px;
  height: 120px;
}
.studio_title:before,
.studio_title:after {
  content: "";
  width: 50px;
  height: 100%;
  position: absolute;
  top: 0;
  background-color: #412a84;
}
.studio_title:before {
  left: -32px;
  border-radius: 25px 0 0 0;
  transform: skew(-15deg);
  box-shadow: -24px 0 0 #251049;
}
.studio_title:after {
  right: -32px;
  border-radius: 0 25px 0 0;
  transform: skew(15deg);
  box-shadow: 24px 0 0 #251049;
}
.studio_title .onair {
  width: 11.5vw;
  height: 5vw;
  display: block;
  position: relative;
}
.studio_title .onair img {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
}
.studio_title p {
  font-size: 4.2vw;
  color: #fff;
  font-weight: 700;
  margin-left: 25px;
  line-height: 5vw;
}
.egg-studio-wrap {
  background-color: #412a84;
  position: relative;
  width: 100%;
  height: 100%;
}
.egg-studio-wrap:after {
  content: "";
  width: 100%;
  height: 100%;
  background: url(../images/egg-studio-new/studio_bg.jpg) right bottom no-repeat;
  background-size: contain;
  position: absolute;
  right: 0;
  bottom: 0;
}
.egg-studio-wrap .flex {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
  position: relative;
  z-index: 2;
  height: 100%;
}
.control_play {
  /* JHS */
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 178px;
  height: 178px;
  display: none;
}
.control_play img {
  /* JHS */
  cursor: pointer;
  width: 100%;
  height: 100%;
}
.videoArea {
  width: 60%;
  height: 100%;
  max-height: 810px;
  max-width: 1155px;
  position: relative;
}
.videoArea .top {
  position: relative;
  height: calc(100% - 7.5vw);
  box-shadow: 4px 4px 8px rgba(0, 39, 66, 0.4);
}
.videoArea .top .imgArea {
  background-color: #000;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}
/* .subtitle {
  background: rgba(255, 255, 255, 0.7);
  padding: 20px 35px;
  position: absolute;
  min-height: 6.9vw;
  width: 60%;
  z-index: 1;
  display: none;
  top: 33.3vw;
  background: ${({isSelected}) => isSelected ? '#dddddd' : '#f3f3f3'};
} */
.timer {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 0;
  background: rgba(80, 131, 218, 0.7);
}
.subtitle .text {
  font-size: 1.6vw;
  color: #000;
  font-weight: 700;
  position: relative;
  z-index: 2;
  padding: 1vw 2vw;
}
.start_dim {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
}
.start_dim p {
  font-size: 3.5vw;
  font-weight: 700;
  text-align: center;
  margin-bottom: 3vw;
}
.result-dim {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: #373737;
  display: flex;
  align-items: center;
  justify-content: center;
}
.result-dim .result_img {
  max-width: 1080px;
  width: 35%;
  margin-right: 5%;
  height: auto;
}
.result-dim .right {
  text-align: center;
}
.result-dim p {
  font-family: "NanumSquareRound", sans-serif;
  font-size: 4vw;
  color: #fff;
  font-weight: 700;
  line-height: 1.3;
}
.result-dim .play-video {
  text-align: center;
  display: block;
  margin-top: 1.5vw;
}
.result-dim .play-video img {
  max-width: inherit;
  width: 7vw;
  display: inline-block;
  cursor: pointer;
}
.videoArea img,
.videoArea video {
  display: block;
  height: 100%;
}
.videoArea video {
  width: 100%;
  object-fit: fill;
}
.character-wrap {
  width: calc(40% - 20px);
  max-width: 717px;
  margin: 0 10px;
}
.characterArea {
  text-align: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-right: 5px;
  width: 100%;
  border-radius: 45px;
  overflow: hidden;
  border: 16px solid rgba(52, 34, 105, 0.5);
}

.characterArea p {
  font-size: 2vw;
  color: #fff;
  background: #5846aa;
  display: inline-block;
  text-align: center;
  font-weight: 700;
  padding: 5px 40px;
  border-radius: 44px;
  height: 3.5vw;
  position: absolute;
  top: 1.5vw;
  z-index: 6;
}
.charcter_inner {
  display: flex;
  margin-top: auto;
  padding: 0 7%;
  width: 100%;
  justify-content: center;
  height: 100%;
  position: relative;
  background: #469dff url(../images/egg-studio-new/character_bg.png) center
    no-repeat;
  background-size: 100% 100%;
  box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.75);
  z-index: 5;
  height: 30vh;
  padding-top: 9vh;
}
.charcter_inner:after {
  content: "";
  width: 97%;
  height: 95%;
  background: url(../images/egg-studio-new/character_bg2.png) left top no-repeat;
  position: absolute;
  left: 8px;
  top: 10px;
  background-size: 100% 100%;
  z-index: 1;
}
.charcter_inner > div {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  position: relative;
  z-index: 2;
  outline: none!important;
  border: none!important;
}
.charcter_inner img {
  display: block;
  object-fit: contain;
  height: 100%;
  max-width: 160%;
  outline: none !important;
  border: none !important;
}
.character-wrap .btn-area {
  margin-top: 18px;
}
.character-wrap .check-btn {
  color: #469be4;
  background-color: #fff;
  background-image: url(../images/quiz-time/check-full.png);
}
.recArea {
  position: absolute;
  right: 5vw;
  bottom: 1vw;
  z-index: 2;
  display: flex;
  align-items: flex-end;
  height: 17vw;
}
.recArea .mic .rec {
  display: block;
  text-align: center;
  margin-bottom: 10px;
}
.recArea .mic .rec em {
  font-style: normal;
  font-size: 25px;
  color: #fff;
  font-weight: 700;
  display: inline-block;
  background: #ff4848;
  padding: 0 10px 0 30px;
  border-radius: 10px;
  position: relative;
}
.recArea .mic .rec em:before {
  content: "";
  position: absolute;
  left: 9px;
  top: 50%;
  width: 14px;
  height: 14px;
  margin-top: -8px;
  background: #fff;
  border-radius: 50%;
}
.recArea .mic {
  position: relative;
  max-width: 179px;
  width: 6.5vw;
  height: 100%;
}
.recArea .mic img {
  max-width: 179px;
  width: 6.5vw;
  cursor: pointer;
  position: absolute;
  left: 0;
  top: 0;
}
.recArea .speaker img {
  max-width: 213px;
  width: 8vw;
  margin-left: 3vw;
}
.record-pop {
  position: absolute;
  bottom:8%;
  left: 30%;
  transform: translateX(-50%);
  background-color: #fff;
  border-radius: 30px;
  box-shadow: 4px 4px 8px rgba(0, 39, 66, 0.4);
  padding: 1.5vw 3vw;
  display: none;
  z-index: 3;
}
.record-pop .buttongroup.img-btn span{
  width: 5vw;
  height: 5vw;
}
.record-pop .buttongroup.img-btn span:hover {
  background: #469be4;
}
@media (min-width: 2000px) {
  .videoArea{max-width: inherit;}
  .character-wrap{max-width: inherit;}
  .charcter_inner{
    height: auto;
  }
}
@media (max-width: 1300px) {
  .charcter_inner{
    height: 22vh;
  }
}
@media (max-width: 1200px) {
  .egg-studio-new .box_parent .screen {
    padding: 20px 0 20px 15px;
  }
  .recArea {
    bottom: 5vh;
    height: 19.8vw;
  }
  .recArea .mic img {
    width: 7.5vw;
  }
  .recArea .speaker img {
    width: 10vw;
  }
  .subtitle {
    padding: 16px 20px;
    min-height: 8.5vw;
  }
  .subtitle .text {
    font-size: 2vw;
  }
  .recArea .mic .rec em {
    font-size: 20px;
    padding: 0 8px 0 26px;
  }
  .recArea .mic .rec em:before {
    width: 12px;
    height: 12px;
    margin-top: -7px;
  }
  .record-pop {
    padding: 1.8vw 3vw;
  }
  .record-pop .buttongroup.img-btn span {
    width: 6vw;
    height: 6vw;
  }
  .characterArea {
    border-width: 11px;
  }
  .character-wrap .btn-area {
    margin-top: 1vw;
  }
  .character-wrap .check-btn {
    font-size: 2vw;
    padding: 1.3vw 2vw 1.3vw 6vw;
    background-size: 3vw 3vw;
  }
  .videoArea .top {
    height: calc(100% - 9.5vw);
  }
}

@media (max-width: 1024px), (max-height: 768px) {
  .egg-studio-new .box_parent {
    padding: 8vw 0 0 0;
  }
  .studio_title {
    height: 8vw;
  }
  .control_play {
    /* JHS */
    width: 14vw;
    height: 14vw;
  }
  .record-pop {
    padding: 20px 35px;
    border-radius: 20px;
    bottom: 13%;
  }
  .record-pop .buttongroup.img-btn span {
    width: 8vh;
    height: 8vh;
  }
  .characterArea p {
    height: auto;
    padding: 0.2vw 2vw;
    top: 2.5vw;
  }
  .charcter_inner {
    padding-top: 5vw;
  }
  .charcter_inner:after {
    width: 96%;
    height: 92%;
  }
  .videoArea {
    width: 59vw;
  }
}

/*태블릿 7인치 이하*/
@media (max-height: 650px) {
  .recArea {
    bottom: 2vh;
    height: 31.7vh;
  }
  .recArea .mic img {
    width: 12vh;
  }
  .recArea .speaker img {
    width: 16vh;
    margin-left: 30px;
  }
  .start_dim p {
    margin-bottom: 3vh;
  }
  .characterArea {
    border-radius: 30px;
    border-width: 9px;
  }
  .studio_title:before,
  .studio_title:after {
    width: 30px;
  }
  .studio_title:before {
    box-shadow: -15px 0 0 #251049;
    left: -8px;
  }
  .studio_title:after {
    box-shadow: 15px 0 0 #251049;
    right: -8px;
  }
  .studio_title p {
    margin-left: 10px;
  }
  .charcter_inner {
    height: 25vh;
  }
  .characterArea p{
    top: 2vw;
  }
}
