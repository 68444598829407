

.container-2 {
	max-width: 1500px;
	margin: 0 auto;
}

.container-3 {
	max-width: 1200px;
	margin: 0 auto;
}

li {
	list-style: none;
}

* {
	margin: 0;
	padding: 0;
	font-family: "NanumSquareRound", sans-serif;
	font-weight: 400;

	/* Regular(400),
	Bold(700),
	Extra Bold(800),
	Light(300) */
}

html, body {
	width: 100%;
	height: 100%;
	/* 태블릿 스크롤 방지용 JHS */
}

.regular {
	font-weight: 400 !important;
}

.bold {
	font-weight: 700 !important;
}

.extra-bold {
	font-weight: 800 !important;
}

.light {
	font-weight: 300 !important;
}

a {
	text-decoration: none;
	color: #333;
}

/*a:hover{
	color: #000;
}*/
h1 {
	/*font-size: 30px;*/
}

.top-text h2{
	font-weight: 800;
}

.table-responsive>table {
	min-width: 750px;
}

.title h2 {
	padding: 30px 0;
	font-size: 28px;
	font-weight: 600;
}

.sm-map ul {
	padding: 0;
}

.sm-map li {
	font-size: 18px;
	color: #868686;
	font-weight: bold;
	padding: 35px 0 35px 10px;
}

.sm-map i {
	color: #868686;
}

.custom-button {
	background-color: #21277b;
	color: #fff;
	border-radius: 5px;
	font-weight: 500;
}

.custom-radio-1 .custom-control-label::before {
	width:24px;
	height:24px;
	border:0;
	background-color:#F0F0F0;
}

.custom-radio-1 .custom-control-label::before,
.custom-radio-1 .custom-control-label::after {
	transform:translateY(-3.5px);
}

.custom-radio-1 .custom-control-label{
	padding-left:10px;
}


.custom-radio-1 .custom-control-input:checked~.custom-control-label::before {
	background-color:#F0F0F0;
	border-color:#F0F0F0;
}

.custom-radio-1 .custom-control-input:checked~.custom-control-label::after {
	background-image:none;
	width:12px;
	height:12px;
	margin-left:6px;
	margin-top:6px;
	border-radius:50%;
	background-color:#D8000D;
}

#wrap:not(.category-show) .category-menu-area {
	display: none;
}

#wrap.category-show .category-menu-area {
	display: block;
}

@media (min-width: 1200px) {
.container, .container-lg, .container-md, .container-sm, .container-xl {
    max-width: 1050px;
}
}

@media (max-width:1500px) {
	.container-2 {
		width: 100%;
	}
}

@media (max-width:1200px) {
	.container-3 {
		width: 100%;
	}
}

.displayNone {
	display: none !important;
}
